<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('contact.update')"
      :title="$t('contact.update')"
      @submitPressed="submitPressed"
    />
    <contacts-form
      v-if="contact"
      :contactObject="contact"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ContactsForm from "./components/ContactsForm.vue";

export default {
  components: {
    TitleBar,
    ContactsForm,
  },
  data() {
    return {
      contact: null,
      action: null,
    };
  },
  created() {
    this.loadContact();
  },
  methods: {
    loadContact() {
      const id = this.$route.params.id;
      this.$Contacts.getResource({ id }).then((response) => {
        this.contact = response.data;
        this.contact.sourceSelect = {
          label: this.$helper.getEnumTranslation('contact_source', this.contact.source, this.$i18n.locale),
          id: this.contact.source
        }
      });
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
